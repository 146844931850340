<template>
  <div class="job">
    <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="banner.bannerBg"></sub-banner>
    <div class="content job-main">
      <van-skeleton title :row="3" :loading="loading">
        <van-collapse v-if="contentJob.jobs && contentJob.jobs.length > 0" v-model="activeName" accordion>
          <van-collapse-item
            v-for="item of contentJob.jobs"
            :key="item.contentItemId"
            :name="item.contentItemId"
            :is-link="link">
            <template #title>
              <div class="title-box">
                <h3>{{item.jobName}}</h3>
                <p>{{item.jobType}} | {{item.location}} | {{item.peoples}} | {{item.publishedTime}}</p>
              </div>
            </template>
            <div class="job-detail">
              <div class="detail_item">
                <h4 class="title">工作概述</h4>
                <p class="item-box" v-html="$options.filters.changeLine(item.workContent)"></p>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <van-empty v-else description="暂无数据" />
        <div class="welfare"  v-if="contentJob.welfare && contentJob.welfare.content">
          <h3>福利说明</h3>
          <p class="description" v-html="$options.filters.changeLine(contentJob.welfare.content)"></p>
          <div class="img-box">
            <img :src="contentJob.welfare.iconUrl" alt="福利说明">
          </div>
        </div>
      </van-skeleton>
    </div>
  </div>
</template>

<script>
import subBanner from '@/components/subBanner'
import { mapActions, mapGetters } from 'vuex'
import pageMixin from '@/mixins/pageMixin'
export default {
  mixins: [pageMixin],
  components: {
    subBanner
  },
  data () {
    return {
      banner: {
        title: '诚聘英才',
        subTitle: 'Talented people',
        bannerBg: require('@/assets/images/job/banner.jpg')
      },
      activeName: '',
      loading: true,
      link: false
    }
  },
  watch: {
    contentJob (n, o) {
      if (n.jobs) {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters(['contentJob'])
  },
  methods: {
    ...mapActions(['fetchContentByKey'])
  },
  created () {
    if (this.contentJob.jobs) {
      this.loading = false
    }
    this.fetchContentByKey('Job')
  }
}
</script>

<style lang="less" scoped>
  .job{
    .job-main{
      padding: 5px 40px;
      .title-box,.job-detail{
        color: @grey;
        h3, h4{
          margin-bottom: 28px;
          color: @black;
          font-size: 34px;
          font-weight: 600;
        }
        p{
          font-size: 26px;
        }
      }
      .job-detail{
        h4{
          color: @grey2;
          font-size: 28px;
        }
        .item-box{
          line-height: 40px;
        }
      }
      /deep/ .van-collapse{
        .van-cell{
          padding: 38px 0 30px;
          overflow: initial;
        }
        .van-collapse-item{
        }
        .van-collapse-item__content{
          padding: 35px 0 48px;
        }
      }
      .welfare{
        margin-top: 100px;
        color: @grey;
        h3{
          margin-bottom: 28px;
          color: @black;
          font-size: 34px;
          font-weight: 600;
        }
        p{
          font-size: 26px;
          margin: 50px 0 100px;
        }
      }
    }
  }
</style>
